import React from "react";
import Layout from "../components/layout";
import Brand from "../components/product-grid/brand";
import Breadcrumb from "../components/breadcrumb";
import ProductGridComponent from "../components/product-grid/product-grid";
import AlgoliaSearch from "algoliasearch";
import GlobalConstants, { Algolia } from "../helpers/constants";
import { Grid } from "@material-ui/core";
import { graphql } from "gatsby";
import { GetComponentByName } from "../helpers/query-data";
import { GQLQuery } from "../types";

/* Returns an object representing the product path. Used to create the breadcrumb.
 * - name -> The brand name of the product (e.g. Nalgene)
 * - string -> The local path to the product (e.g. nalgene-32oz-otf-bottle.html)
 */
const GetPathObj = (name: string, path: string): Record<string, unknown> => {
  const pathObj = {};

  pathObj[GlobalConstants.Home] = "/";
  pathObj[GlobalConstants.Products] = "/products";
  pathObj[`${name}®`] = "/products" + path;

  return pathObj;
};

type ProductGridProps = {
  data: {
    crystallize: GQLQuery;
  };
  pageContext: {
    slug: string;
    logo: string;
  };
};

/* Creates a grid of products for pages like /products/nalgene
 * - crystallize -> GraphQL query
 * - logo -> An image representing the brand of products
 */
const ProductGrid = (props: ProductGridProps): JSX.Element => {
  const QueryData = props.data.crystallize.catalogue;
  const DescComponent = GetComponentByName(
    QueryData.components,
    GlobalConstants.Description
  );
  const PathObj = GetPathObj(QueryData.name, QueryData.path);
  const [State, SetState] = React.useState([]);

  /* Fetches products on page load. This must be this way since
    "USA made" is stored on Algolia */
  React.useEffect(() => {
    const Client = AlgoliaSearch(Algolia.AppId, Algolia.ApiKey);
    const Index = Client.initIndex(Algolia.IndexName);
    const Query = props.pageContext.slug
      .split("/")
      .join("")
      .split("-")
      .join(" ");
    Index.search(Query).then(({ hits }) => SetState(hits));
  }, [props.pageContext.slug]);

  return (
    <Layout>
      <Breadcrumb pathObj={PathObj}></Breadcrumb>
      <Grid item xs={12} sm={4} md={3}>
        <Brand
          brand={QueryData.name}
          logo={{
            url: "/" + props.pageContext.logo,
            altText: props.pageContext.logo,
          }}
          description={DescComponent}
        />
      </Grid>
      <ProductGridComponent gridItems={State} />
    </Layout>
  );
};

export const Query = graphql`
  query($slug: String!) {
    crystallize {
      catalogue(path: $slug) {
        name
        path
        components {
          ...PG_component
        }
      }
    }
  }

  fragment PG_component on CRYSTALLIZE_Component {
    name
    content {
      ...PG_richText
    }
  }

  fragment PG_richText on CRYSTALLIZE_RichTextContent {
    html
  }
`;

export default ProductGrid;
